import { Plugin, ButtonView } from 'ckeditor5';

export default class SaveButtonPlugin extends Plugin {
    init() {
        const editor = this.editor;
        const handleSave = editor.config.get('handleSave');

        editor.ui.componentFactory.add("saveButton", locale => {
            const view = new ButtonView(locale);

            view.set({
                label: 'Save',
                withText: true,
                class: 'simplebutton',
                tooltip: true
            });

            view.on("execute", () => {
                const data = editor.getData();
                if (handleSave) {
                    handleSave(data); 
                } else {
                    console.warn('handleSave function is not defined.');
                }
            });

            return view;
        });
    }
}