import { Plugin, ButtonView } from 'ckeditor5';

export default class OpenFilePlugin extends Plugin {
    init() {
        const editor = this.editor;
        const handleFileUpload = editor.config.get('handleFileUpload');

        editor.ui.componentFactory.add("openFile", locale => {
            const view = new ButtonView(locale);

            view.set({
                label: 'Open File',
                withText: true,
                class: 'simplebutton',
                tooltip: true
            });

            view.on("execute", () => {
                handleFileUpload()
            });

            return view;
        });
    }
}